import {allow, policyEvents} from "../../Components/Authorize";
import * as config from "../../SiteConfig/siteConstants";
import {isTrimmedStringEmpty} from "../../Utilities/commonUtilities";
import {getRoleId} from "../../Utilities/LocalStorage/storageUtilities";
import {differenceInDatesGreaterThanMax} from "../../Utilities/Types/dateUtilities";
import {courseTypes as courseType} from "../Course/courseConstants";
import {getReportDetails} from "./reportUtility";

export function reportTypeObject(reportTitle,
                                 reportDescription = "",
                                 usesInfoFilter = false,
                                 roles = [],
                                 courseTypes = [courseType.RegularCourse],
                                 onlyIncludesCoursesWithAssessments = false,
                                 requiresCourseSelection = false,
                                 htmlComponent = null,
                                 datesAffectTraining = false,
                                 requiresDistrictOrCourseSelection = false,
                                 maxDateRange = null) {
    return {
        courseTypes,
        datesAffectTraining,
        htmlComponent,
        maxDateRange,
        onlyIncludesCoursesWithAssessments,
        reportDescription,
        roles,
        reportTitle,
        requiresCourseSelection,
        requiresDistrictOrCourseSelection,
        usesInfoFilter
    };
}

export const reportSearchModel = (pageId, siteGroupId, startDate, endDate, reportType, courseId, creditType, regionId, quarterNumber, userInfo, districtName, categoryId) => {
    return {
        siteGroupId: siteGroupId || config.APH_SITE_GROUP_ID,
        pageId: pageId.toString() || "",
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : null,
        reportType: reportType ? reportType : "",
        categoryId: categoryId || 0,
        courseId: courseId || 0,
        creditType: creditType ? creditType : "",
        userInfo: userInfo ?? "",
        regionId: regionId || 0,
        districtName: districtName || "",
        quarterNumber: quarterNumber || "",
        selectedRole: getRoleId(),
        ClearCriteria: clearCriteria,
        ValidateModel: validateModel,
        RequiresCourseSelection: requiresCourseSelection,
        RequiresDistrictOrCourseSelection: requiresDistrictOrCourseSelection,
        IsMaxDateRangeExceeded: isMaxDateRangeExceeded
    };
};

function clearCriteria() {
    this.startDate = null;
    this.endDate = null;
    this.reportType = "";
    this.courseId = 0;
    this.categoryId = 0;
    this.creditType = "";
    this.regionId = 0;
    this.districtName = "";
    this.quarterNumber = "";
    this.userInfo = "";
    this.selectedRole = getRoleId();
}

function validateModel(allSiteDetails) {
    const isAllowedToSelectDistrict = allow(policyEvents.VIEW_REGIONS, false, this.selectedRole);
    const hasSelectedADistrictOrCourse = (isAllowedToSelectDistrict && !isTrimmedStringEmpty(this.districtName)) || this.courseId > 0;

    return this.startDate
        && this.endDate
        && this.reportType
        && this.siteGroupId > 0
        && ( !requiresCourseSelection(this.reportType, this.siteGroupId, allSiteDetails, this.selectedRole) || (this.courseId > 0) )
        && ( !requiresDistrictOrCourseSelection(this.reportType, this.siteGroupId, allSiteDetails, this.selectedRole) || hasSelectedADistrictOrCourse)
        && !isMaxDateRangeExceeded(this.reportType, this.siteGroupId, allSiteDetails, this.selectedRole, this.startDate, this.endDate);
}

function requiresCourseSelection(reportType, siteGroupId, allSiteDetails, selectedRole) {
    const reportDetails = getReportDetails(reportType, siteGroupId, allSiteDetails, selectedRole);
    return reportDetails?.requiresCourseSelection ?? false;
}

function requiresDistrictOrCourseSelection(reportType, siteGroupId, allSiteDetails, selectedRole) {
    const reportDetails = getReportDetails(reportType, siteGroupId, allSiteDetails, selectedRole);
    return reportDetails?.requiresDistrictOrCourseSelection ?? false;
}

function isMaxDateRangeExceeded(reportType, siteGroupId, allSiteDetails, selectedRole, startDate, endDate) {
    const reportDetails = getReportDetails(reportType, siteGroupId, allSiteDetails, selectedRole);
    return differenceInDatesGreaterThanMax(reportDetails?.maxDateRange, startDate, endDate);
}